import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useApi } from '../hooks/use-api.hook'
import { useUser } from '../hooks/use-user.hook'

import { Container, Row, Button } from 'react-bootstrap'

import { SpeckNavBar } from '../components/NavBar'
import SideBarc from '../components/SideBarc'
import LoginButton from '../components/LoginButton'
import GoBackButton from '../components/GoBackButton'

import './KYC.css'
import { useNavigate } from 'react-router-dom'
// import '../components/Buy-tokens-button.css';

const KYCPage = () => {
  const navigate = useNavigate()

  const user = useUser()

  const [identityVerification, setIdentityVerification] = useState({
    governmentId: '',
    proofOfAddress: '',
    date_of_birth: '',
    full_name: ''
  })

  const [addressVerification, setAddressVerification] = useState({
    residential_address: '',
    mailing_address: ''
  })

  const [additionalInformation, setAdditionalInformation] = useState({
    occupation: '',
    source_of_income: '',
    purpose_of_account_opening: '',
    beneficial_ownership_information: ''
  })

  const [bankingInformation, setBankingInformation] = useState({
    bank_account_number: '',
    bank_name: '',
    branch_address: '',
    iban_swift_code: ''
  })

  const [companyInformation, setCompanyInformation] = useState({
    company_name: '',
    company_registration_number: '',
    business_address: '',
    list_of_trustees: ''
  })

  const [files, setFiles] = useState({
    governmentIdFile: null,
    proofOfAddressFile: null
  })

  const handleInputChange = (event, setState) => {
    const { name, value } = event.target
    setState((prevValues) => ({ ...prevValues, [name]: value }))
  }

  const handleFileChange = (event, setState) => {
    const file = event.target.files[0]
    setState((prevFiles) => ({ ...prevFiles, [event.target.name]: file }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formData = new FormData()
    Object.keys(identityVerification).forEach((key) => {
      formData.append(`kyc[${key}]`, identityVerification[key])
    })
    Object.keys(addressVerification).forEach((key) => {
      formData.append(`kyc[${key}]`, addressVerification[key])
    })
    Object.keys(additionalInformation).forEach((key) => {
      formData.append(`kyc[${key}]`, additionalInformation[key])
    })
    Object.keys(bankingInformation).forEach((key) => {
      formData.append(`kyc[${key}]`, bankingInformation[key])
    })
    Object.keys(companyInformation).forEach((key) => {
      formData.append(`kyc[${key}]`, companyInformation[key])
    })
    if (files.governmentIdFile) {
      formData.append('kyc[government_issued_id_card]', files.governmentIdFile)
    }
    if (files.proofOfAddressFile) {
      formData.append('kyc[proof_of_address]', files.proofOfAddressFile)
    }

    try {
      const response = await axios.post(
        'https://0g6o56vy4j.execute-api.us-east-2.amazonaws.com/kycs.json',
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      console.log(response.data)
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message)
    }
  }

  useEffect(() => {
    if (!user) {
      navigate('/login?re=kyc')
    }
  }, [user, navigate])

  return (
    <>
      {/* <SpeckNavBar /> */}
      <SideBarc />
      <div className="navbar1">
        <GoBackButton></GoBackButton>
        <LoginButton />
      </div>
      <Container className="py-4 all">
        {/* <div> */}
        <h1>KYC Page </h1>
        <hr className="dividerrr" />
        <form className="card-containerr" onSubmit={handleSubmit} id="a-form">
          {/* adding cards #####################################################################*/}
          <div class="custom-cardD">
            <h2>Identity Verification</h2>
            <div className="fileUpLoadGroup">
              <div className="headerForButton">Upload Government-issued ID card</div>
              <div className="fileUpload">
                <input
                  type="file"
                  name="governmentIdFile"
                  onChange={(event) => handleFileChange(event, setFiles)}
                />
              </div>
              <hr className="dividerrr" />
            </div>
            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="full_name"
                name="full_name"
                value={identityVerification.full_name}
                onChange={(event) => handleInputChange(event, setIdentityVerification)}
                required
              />
              <label for="name" class="form__label">
                Full name
              </label>
            </div>
            <div className="datePicker">
              <div>Date of Birth</div>
              <div>
                <input
                  type="date"
                  name="date_of_birth"
                  value={identityVerification.date_of_birth}
                  onChange={(event) => handleInputChange(event, setIdentityVerification)}
                />
              </div>
            </div>
          </div>
          {/* adding cards #####################################################################*/}
          <div class="custom-cardD">
            <h2>Address Verification</h2>
            <div className="fileUpLoadGroup">
              <div className="headerForButton">Upload Proof of address</div>
              <div className="fileUpload">
                <input
                  type="file"
                  name="proofOfAddressFile"
                  onChange={(event) => handleFileChange(event, setFiles)}
                />
              </div>
              <hr className="dividerrr" />
            </div>
            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="residential_address"
                name="residential_address"
                value={addressVerification.residential_address}
                onChange={(event) => handleInputChange(event, setAddressVerification)}
                required
              />
              <label for="name" class="form__label">
                Residential address
              </label>
            </div>
            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="mailing_address"
                name="mailing_address"
                value={addressVerification.mailing_address}
                onChange={(event) => handleInputChange(event, setAddressVerification)}
                required
              />
              <label for="name" class="form__label">
                Mailing address (if different from residential address)
              </label>
            </div>
          </div>
          {/* adding cards #####################################################################*/}
          <div class="custom-cardD">
            <h2>Additional Information</h2>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="occupation"
                name="occupation"
                value={additionalInformation.occupation}
                onChange={(event) => handleInputChange(event, setAdditionalInformation)}
                required
              />
              <label for="name" class="form__label">
                Additional Information
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="source_of_income"
                name="source_of_income"
                value={additionalInformation.source_of_income}
                onChange={(event) => handleInputChange(event, setAdditionalInformation)}
                required
              />
              <label for="name" class="form__label">
                Source of income
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="purpose_of_account_opening"
                name="purpose_of_account_opening"
                value={additionalInformation.purpose_of_account_opening}
                onChange={(event) => handleInputChange(event, setAdditionalInformation)}
                required
              />
              <label for="name" class="form__label">
                Purpose of account opening
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="beneficial_ownership_information"
                name="beneficial_ownership_information"
                value={additionalInformation.beneficial_ownership_information}
                onChange={(event) => handleInputChange(event, setAdditionalInformation)}
                required
              />
              <label for="name" class="form__label">
                Beneficial ownership information
              </label>
            </div>
          </div>
          {/* adding cards #####################################################################*/}

          <div class="custom-cardD">
            <h2>Banking Information</h2>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="bank_account_number"
                name="bank_account_number"
                value={bankingInformation.bank_account_number}
                onChange={(event) => handleInputChange(event, setBankingInformation)}
                required
              />
              <label for="name" class="form__label">
                Bank account number
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="bank_name"
                name="bank_name"
                value={bankingInformation.bank_name}
                onChange={(event) => handleInputChange(event, setBankingInformation)}
                required
              />
              <label for="name" class="form__label">
                Bank name
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="branch_address"
                name="branch_address"
                value={bankingInformation.branch_address}
                onChange={(event) => handleInputChange(event, setBankingInformation)}
                required
              />
              <label for="name" class="form__label">
                Branch address
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="iban_swift_code"
                name="iban_swift_code"
                value={bankingInformation.iban_swift_code}
                onChange={(event) => handleInputChange(event, setBankingInformation)}
                required
              />
              <label for="name" class="form__label">
                IBAN/SWIFT
              </label>
            </div>
          </div>
          {/* adding cards #####################################################################*/}

          <div class="custom-cardD">
            <h2>Company Information</h2>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="company_name"
                name="company_name"
                value={companyInformation.company_name}
                onChange={(event) => handleInputChange(event, setCompanyInformation)}
                required
              />
              <label for="name" class="form__label">
                Company name
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="company_registration_number"
                name="company_registration_number"
                value={companyInformation.company_registration_number}
                onChange={(event) => handleInputChange(event, setCompanyInformation)}
                required
              />
              <label for="name" class="form__label">
                Company registration number
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                id="business_address"
                name="business_address"
                value={companyInformation.business_address}
                onChange={(event) => handleInputChange(event, setCompanyInformation)}
                required
              />
              <label for="name" class="form__label">
                Business address
              </label>
            </div>

            <div class="form__group field">
              <input
                type="text"
                class="form__field"
                placeholder=""
                name="list_of_trustees"
                id="list_of_trustees"
                value={companyInformation.list_of_trustees}
                onChange={(event) => handleInputChange(event, setCompanyInformation)}
                required
              />
              <label for="name" class="form__label">
                List of trustees
              </label>
            </div>

            {/* Testing this style */}
          </div>
          <div></div>
        </form>
        <div className="submitButton">
          <button className="pill-buttonn " type="submit" form="a-form">
            Submit
          </button>
        </div>
        {/** testing a button style */}
        {/* <div class="file-input">
          <input type="file" id="file" class="file"/>
          <label for="file">
            Select file
            <p class="file-name"></p>
          </label>
        </div> */}
        {/** testing a button style */}
      </Container>
    </>
  )
}

export default KYCPage
